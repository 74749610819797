export default {
  heading: 'Kulim Park, sans-serif',
  subheading: 'Chamber Display Semibold Italic',
  body: 'Lora, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Playfair Display, serif',
  cursive: 'Great Vibes',
  googleFonts: [
    'Kulim Park:100,200,300,400,500,600,700,800,900',
    'Lora:400,500,600,700,800,900',
    'DM Serif Display:400',
    'Great Vibes'
  ],
  customFamilies: ['Chamber Display Semibold Italic'],
  customUrls: ['https://www.gonation.biz/fonts/chamber-display/chamber-display.css']
}
