export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  accessibilityWidget: {},

  navigation: {
    '.container': {
      backgroundColor: 'transparent',
      padding: ['0.5rem', '0.5rem', '1rem', '1rem'],
      zIndex: '1001'
    },

    '.smallNavMenu > div': {
      border: 'none',
      '.navItem': {
        color: 'text',
        a: {
          fontSize: ['1rem', '1rem', '1rem', '1rem']
        }
      }
    },

    '.navItem': {
      color: 'light',
      margin: '0rem',
      padding: ['0.75rem', '0.75rem', '1rem'],
      width: '100%',
      flexGrow: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ':hover': {
        color: 'inherit'
      }
    },
    '.hamburger > div': {
      backgroundColor: 'white'
    },

    '.navMenuLogo': {
      position: 'static',
      width: ['100%', '', '100%'],
      padding: ['0.5rem', '', '2rem'],
      borderBottom: '1px solid',
      borderColor: 'secondary',
      margin: '0rem',
      img: {
        maxHeight: ['55px', '', '100px', '']
      }
    },
    '.navMenuOpen': {
      backgroundColor: 'primary',
      width: ['90%', '', '40%'],
      justifyContent: ['flex-start', 'flex-start', 'flex-start'],
      alignItems: 'flex-start',
      padding: '0rem',
      '.seperatorLine': {
        width: '100%',
        maxWidth: 'unset',
        borderColor: 'secondary',
        margin: '1rem 0rem 0rem'
      },
      '.navItem': {
        padding: ['0.75rem 1rem', '0.75rem 1rem', '1rem 1rem', '0.75rem 2rem', '0.75rem 2.5rem', '1rem 3rem'],
        justifyContent: 'flex-start',
        a: {
          fontSize: ['1rem', '1.25rem', '1.5rem', '1.75rem']
        }
      }
    },
    '.navBlockOpen': {
      position: 'fixed',
      left: '0rem',
      top: '0rem',
      width: '100%',
      height: '100vh',
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgb(30 24 6 / 70%)'
    },
    '.phoneSocialContainer': {
      width: '100%',
      maxWidth: 'unset',
      backgroundColor: 'black',
      paddingBottom: '1rem'
    },
    '.socialIconsContainer, .phoneContainer': {
      '.smallNavHeading': {
        fontSize: ['1.1rem', '1.5rem'],
        fontFamily: 'subheading'
      },
      margin: '0rem',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'light',
      border: 'none',
      width: '100%',
      fontSize: '1rem',
      maxWidth: 'unset',
      svg: {
        path: {
          fill: 'light'
        }
      }
    },

    '.containerScrolled': {
      backgroundColor: 'black',
      borderBottom: '3px solid',
      borderColor: 'secondary',
      zIndex: '1001',
      '.hamburger > div': {
        backgroundColor: 'secondary'
      }
    },
    '.logo': {
      img: {
        opacity: '1',

        maxHeight: ['75px', '85px']
      }
    },
    '.logoScrolled': {
      img: {
        opacity: '1',
        maxHeight: ['55px', '60px']
      }
    }
  },

  footer: {
    padding: ['0rem', '', '', '0rem'],
    backgroundColor: '#283d1d',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    '::after': {
      content: `"© ${new Date().getFullYear()} Zucca Gastrobar"`,
      width: '100%',
      backgroundColor: 'background',
      color: 'white',
      padding: '2rem 0rem',
      marginBottom: ['40px', '', '', '0px'],
      textTransform: 'uppercase',
      order: '6',
      textAlign: 'center',
      fontFamily: 'heading'
    },
    '.logo': {
      maxWidth: '200px'
    },
    '.contactDetails-container': {
      margin: '2rem 0rem'
    },
    '.sectionHeading': {
      color: 'white',
      borderBottom: '0rem',
      fontSize: '1.5rem',
      fontFamily: 'body',
      textTransform: 'uppercase',
      marginBottom: ['0rem', '', '', '1rem']
    },
    '.column': {
      marginBottom: '0rem',
      padding: '3rem 2rem',
      width: ['', '', '', '20%'],
      flexGrow: '1',
      maxWidth: ['unset', '', '', '350px']
    },
    '.contactColumn': {
      borderRight: 'none !important',
      padding: '3rem 2rem !important'
    },
    '.exploreColumn': {
      order: ['5', '', '', '5'],
      backgroundColor: '#0a1b09',
      margin: ['', '', '', '0 0 0 auto'],
      marginBottom: '0rem',
      padding: ['', '', '4rem 3rem', '4rem 4rem'],
      width: ['100%', '', '', '40%'],
      '.sectionHeading': {
        color: 'white',
        fontSize: '2rem',
        textAlign: 'left',
        width: '100%',
        padding: ['1.25rem', '', '', '0rem'],
        fontWeight: '400'
      },

      '.multiButtonContainer': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        a: {
          width: '100%',
          color: 'primary',
          fontSize: ['1rem', '', '1.25rem'],
          letterSpacing: '1px',
          fontWeight: '600',
          padding: '0.25rem 0rem',
          color: 'secondary'
        }
      }
    },
    '.poweredBy  .gonationLogo': {
      filter: 'unset'
    },
    '.copyright': {
      display: 'none'
    },

    '.socialContainer svg path': {
      fill: 'white'
    },
    '.footerAboutButton span': {
      variant: 'buttons.primary',
      fontSize: '0.9rem',
      padding: '0.75rem 1.5rem'
    },
    '.button': {
      variant: 'buttons.primary',
      borderRadius: '500px',
      padding: '0.75rem 1.5rem'
    },
    '.aboutText': {
      color: 'text'
    },
    '.popUp': {
      '.content': {
        padding: '3rem 1rem',
        '.title': {
          fontSize: '1.5rem',
          opacity: '0.7'
        }
      },
      '.locations': {
        a: {
          variant: 'buttons.primary'
        }
      }
    }
  },

  ctaWidget: {
    // display: 'none',
    a: {
      backgroundColor: 'secondary'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: 'fit-content',
    // borderBottom: '2px solid',
    // borderColor: 'secondary',
    display: 'flex',
    flexDirection: 'column',
    fontSize: ['2rem', '2.25rem', '2.5rem', '2.75rem'],
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: 'bolder',
    letterSpacing: '2px',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem'
    // textShadow:
    //   '#896d36 -2.5px -2.5px 0px, #896d36 2.5px -2.5px 0px, #896d36 -2.5px 2.5px 0px, #896d36 2.5px 2.5px 0px',

    // WebkitTextStroke: '2px #487035'
  },

  subtitle: {
    color: 'secondary',
    textTransform: 'initial',
    order: '1',
    fontFamily: 'subheading',
    fontSize: ['1.2rem', '1.5rem', '1.75rem'],
    marginBottom: '1.25rem',
    letterSpacing: '1px'
  },

  text: {
    order: '3'
  },

  sideBySide1: {
    overflow: 'hidden',
    '.content': {
      width: ['', '', '45%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      '.title': {
        variant: 'customVariants.title',
        '::after': {
          content: "''",
          borderBottom: '2px white solid',
          width: '40vw',
          height: '2px',
          left: '-50%',
          mt: '1.5rem',
          position: 'relative'
        }
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text',
        position: 'relative',
        left: ['', '', '1rem']
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      zIndex: '10',
      width: ['', '', '55%'],
      height: ['', '', '75vh'],
      '::before': {
        background: 'unset'
      },
      img: {
        padding: ['1rem', '', '2rem', '2rem', '3rem']
        // height: '100vh',
      }
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide2: {
    overflow: 'hidden',
    flexDirection: ['', '', 'row'],
    '.imageContainer': {
      order: '2',
      width: ['', '', '60%'],
      height: ['', '', '95vh'],
      position: 'relative',
      '::before': {
        content: ['none', 'none', "''"],
        border: 'solid 3px',
        borderColor: 'secondary',
        position: 'absolute',
        height: '85%',
        width: '50vw',
        left: '10%',
        top: '15%'
      },
      img: {
        // height: '100vh',
        border: 'solid 3px',
        borderColor: 'secondary'
      },
      '.image1': {
        position: ['', '', 'absolute'],
        top: '0%',
        left: '25%',
        width: ['', '', '75%'],
        height: ['', '', '75%']
      },
      '.image3': {
        position: ['', '', 'absolute'],
        bottom: '10%',
        width: ['', '', '60%'],
        height: ['', '', '50%']
      }
    },

    '.content': {
      order: '2',
      width: ['', '', '40%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      },
      '.ctaButton': {
        variant: 'buttons.secondary'
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.section': {
      border: 'none',
      width: ['100%', '', '75%'],
      maxWidth: 'unset',
      margin: '0rem auto',
      height: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center'
    },
    '.title': {
      variant: 'customVariants.title',
      border: 'none',
      padding: '0rem',
      fontSize: ['5rem', '6.5rem', '7.5rem', '8.5rem'],
      textShadow: '2px 2px 10px black'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'white',
      fontWeight: 'normal'
    },
    '.text': {
      color: 'white'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    '.imageContainer': {
      order: ['2', '', '2']
    },
    maxHeight: ['', '', '50vh'],
    '.content': {
      order: ['1', '1'],
      padding: ['1rem', '', '', '4rem', '5rem'],
      alignItems: 'flex-start',
      textAlign: 'left'
    },
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'left',
      margin: '1rem 0rem',
      // display: 'none',
      fontWeight: 'normal',
      border: 'none',
      // borderBottom: '2px solid',
      // borderColor: 'secondary',
      width: 'fit-content'
      // fontSize: '0rem',
      // color: 'background',
      // '::before': {
      //   variant: 'customVariants.title',
      //   content: "'Recent News'", // ! bad coding but could't be bothered to shadow
      // },
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'left',
      margin: '1rem 0rem'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left',
      margin: '1rem 0rem 2rem',
      maxWidth: 'unset'
    },
    '.date': {
      textAlign: 'left',
      margin: '1rem 0rem',
      color: 'white',
      order: '2'
    },
    '.shoutCTABtns': {
      order: '5',
      '.shoutCTA': {
        variant: 'buttons.primary',
        padding: '1rem',
        fontSize: '1rem'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide2',
    '.section': {
      margin: '0rem 0rem',
      padding: '2rem 10%',
      alignItems: 'flex-start',
      justifyContent: 'center'
    },

    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'black'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left',
      '> *': {
        color: 'white'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2'
  },

  homepageLocation: {
    // variant: 'customVariants.sideBySide1',
    padding: ['5rem 1rem', '', '10rem 1rem'],
    '.section': {
      maxWidth: 'unset'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: ['3rem 1rem', '', '6rem 1rem', '7rem 1rem'],
    '.title': {
      fontSize: '2.5rem',
      color: 'secondary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    }
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    border: 'solid 1px black',
    padding: ['1rem', '1rem'],
    margin: ['130px 0.5rem', '', '', '', '130px auto 3rem'],
    width: ['calc(100% - 1rem)'],
    backgroundColor: 'white',
    '.menuContainer': {
      paddingBottom: '1rem'
    },

    '#sec-sUdMFCV4RrCnA11Q8HmWRA': {
      // lunch menu
      position: 'relative',
      // height: '100vh',
      '::before': {
        content: '""',
        // position: 'absolute',
        // top: '0',
        // left: '0',
        // width: '100%',
        // height: '100%',
        backgroundImage:
          'url(https://res.cloudinary.com/gonation/image/upload/v1687539564/sites/zucca/lunch-menu1.jpg)',

        // https://res.cloudinary.com/gonation/image/upload/v1687539564/sites/zucca/lunch-menu1.jpg
        // https://res.cloudinary.com/gonation/image/upload/v1687539564/sites/zucca/lunch-menu2.jpg
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        width: '100%',
        height: ['75vh', '', '', '120vh'],
        transform: 'scale(1.05)'
      },
      '::after': {
        content: '""',

        // top: '0',
        // left: '0',
        width: '100%',
        height: '100%',
        backgroundImage:
          'url(https://res.cloudinary.com/gonation/image/upload/v1687539564/sites/zucca/lunch-menu2.jpg)',

        // https://res.cloudinary.com/gonation/image/upload/v1687539564/sites/zucca/lunch-menu1.jpg
        // https://res.cloudinary.com/gonation/image/upload/v1687539564/sites/zucca/lunch-menu2.jpg
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        width: '100%',
        height: ['75vh', '', '', '120vh'],
        transform: 'scale(1.05)'
      },
      '.menuContainer': {
        display: 'none'
      }
    },
    '#sec-Xkk__isVQDSFDjld7qrQXQ': {
      // Catering Menu
      position: 'relative',
      // height: '100vh',
      '::before': {
        content: '""',
        // position: 'absolute',
        // top: '0',
        // left: '0',
        // width: '100%',
        // height: '100%',
        backgroundImage:
          'url(https://res.cloudinary.com/gonation/image/upload/v1687539564/sites/zucca/catering-menu1.jpg)',

        // https://res.cloudinary.com/gonation/image/upload/v1687539564/sites/zucca/lunch-menu1.jpg
        // https://res.cloudinary.com/gonation/image/upload/v1687539564/sites/zucca/lunch-menu2.jpg
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        width: '100%',
        height: ['75vh', '', '', '120vh'],
        transform: 'scale(1.05)'
      },
      '::after': {
        content: '""',

        // top: '0',
        // left: '0',
        width: '100%',
        height: '100%',
        backgroundImage:
          'url(https://res.cloudinary.com/gonation/image/upload/v1687539564/sites/zucca/catering-menu2.jpg)',

        // https://res.cloudinary.com/gonation/image/upload/v1687539564/sites/zucca/lunch-menu1.jpg
        // https://res.cloudinary.com/gonation/image/upload/v1687539564/sites/zucca/lunch-menu2.jpg
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        width: '100%',
        height: ['75vh', '', '', '120vh'],
        transform: 'scale(1.05)'
      },
      '.menuContainer': {
        display: 'none'
      }
    },
    '.tabsRow': {
      padding: '0rem 0rem 1rem',
      marginBottom: '2rem'
    },
    '#menuTab': {
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      color: 'secondary',
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.7'
      }
    },
    '.menuItemInnerContainer, .menuItemContentContainer': {
      margin: '0rem'
    },
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      fontFamily: 'cursive',
      textTransform: 'capitalize',
      color: 'secondary',
      margin: '2rem 0rem 0rem',
      textAlign: 'left',
      fontSize: ['1.75rem', '', '2rem'],
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%',
      position: 'relative',
      px: '15%',
      '::before': {
        content: "''",
        position: 'absolute',
        top: '50%',
        left: '0%',
        transform: 'translateY(-50%)',
        height: '3px',
        backgroundColor: 'black',
        width: '10%'
      },
      '::after': {
        content: "''",
        position: 'absolute',
        top: '50%',
        right: '0%',
        transform: 'translateY(-50%)',
        height: '3px',
        backgroundColor: 'black',
        width: '10%'
      }
    },

    '.menuItemName': {
      color: 'dark',
      fontFamily: 'heading',
      fontWeight: '500',
      fontSize: ['1.2rem', '1.3rem', '', ''],
      mb: '0rem'
    },

    '.menuSectionDescription': {
      fontSize: '1.1rem',
      margin: ['0rem', '', '0rem 0rem 1.5rem'],
      color: 'dark',
      fontFamily: 'heading',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%'
    },
    '.menuItemsContainer': {
      mt: '1.5rem'
    },
    '.menuItemDescription': {
      color: 'primary'
    },
    '.menuPriceContainer': {
      fontFamily: 'heading',
      // display: 'none',
      color: 'black'
    },
    '.menuItemPrice': {
      color: 'dark',
      fontFamily: 'heading'
    },
    '.variantsContainer': {
      color: 'black'
      // display: 'none',
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {
      display: 'none'
    },
    '.albumTitle': {
      variant: 'buttons.secondary'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      img: {
        // filter: 'brightness(1) invert(1)'
      }
    },
    '.eventItemImage': {
      minHeight: 'unset',
      maxHeight: 'unset',
      height: '100%'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    order: '4',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'white',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.secondary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },

  locationMap: {
    order: '2',
    backgroundColor: 'secondary',
    color: 'white',
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderBottom: '2px solid',
      borderColor: 'white'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    },

    '.hours-section': {}
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  eventsLeft: {
    variant: 'customVariants.sideBySide1'
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2'
  },

  // ? ===============================
  // ? ====  Private Dining Menu  ====
  // ? ===============================

  privateDiningIntro: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    '.boxesHeading': {
      display: 'none'
    },
    '.box': {
      backgroundColor: '#c5c2c2',
      margin: ['1rem', '', '2rem'],
      width: ['100%', 'calc(50% - 0.5rem * 2)', 'calc(33.3% - 2rem * 2)']
    },
    '.imageContainer': {
      backgroundColor: 'black',
      img: {
        objectFit: 'contain'
      }
    },
    '.subtitle': {
      color: '#5d574e'
    }
  },

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {},

  // ? ========================
  // ? ====  Reservations  ====
  // ? ========================
  reservations: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10vh 3rem',
    backgroundColor: '#141414'
  }
}
